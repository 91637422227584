import React from "react"
import { useTranslation } from "react-i18next"

import sanitizeHtml from "sanitize-html"

import { graphql } from "gatsby"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroText from "../components/hero-text/hero-text"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Content from "../components/content/content"
import Footer from "../components/footer/footer"
import PostContent from "../components/post-content/post-content"

export default function Security({ location, data }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.security.title")
  const PageDescription = t("meta.description")

  const PageContent = { __html: sanitizeHtml(data.markdownRemark.html) }

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroText
        title={data.markdownRemark.frontmatter.title}
        idTitle="bugs-hero-title"
      >
        <Container>
          <Header classVariant="headerText" location={location} />
        </Container>
      </HeroText>
      <Content classVariant="contentPage">
        <Container>
          <PostContent id="bugs-content">
            <div dangerouslySetInnerHTML={PageContent}></div>
          </PostContent>
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query SecurityQuery($mdPath: String!) {
    markdownRemark(fileAbsolutePath: { regex: $mdPath }) {
      html
      frontmatter {
        title
      }
    }
  }
`
